import React from "react";
import Footer from "./Footer";
import Header from "./Header";

interface AuxProps {
  children: React.ReactNode
}

const MainLayout = ({ children }: AuxProps) => {
  return (
    <div className="flex flex-col max-h-screen justify-between overflow-y-hidden">
      <Header />
      <div className="w-screen">
        <main className="z-10 display m-auto ">
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
// 7D8F69
// 7D8F69
// A9AF7E
// 557153 max-h-[calc(100vh-130px)]