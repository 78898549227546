import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import MainLayout from "./layouts/Main";
import MainRoutes from "./routes/index";
import MainStore from "./redux/store";

function App() {
  return (
    <BrowserRouter>
      <Provider store={MainStore}>
        <MainLayout>
          <MainRoutes />
        </MainLayout>
      </Provider>
    </BrowserRouter>

  );
}

export default App;
