import { HashLoader } from "react-spinners";

function Spinner() {
  return (
    <div className="container h-full flex justify-center items-center">
      <HashLoader color={'#557153'} />
    </div>
  );
}

export default Spinner;
