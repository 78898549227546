import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// export const sigmoidApi = createApi({
//   baseQuery: fetchBaseQuery({
//     baseUrl: "https://simbah.ptsentratekmetalindo.com/",
//   }),
//   keepUnusedDataFor: 0,
//   refetchOnMountOrArgChange: true,
//   refetchOnFocus: true,
//   reducerPath: "simbahApi",
//   tagTypes: ["MappingMessage"],
//   endpoints: () => ({}),
// });

export const urlApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "https://shortlinkapi.ptsentratekmetalindo.com/",
  }),
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  reducerPath: "urlApi",
  tagTypes: ["urlMapping"],
  endpoints: () => ({}),
});

export const sigmoidApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "https://sigmoid.ptsentratekmetalindo.com/",
  }),
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  reducerPath: "sigmoidApi",
  tagTypes: ["NotesMapping", "MappingMessageSimbah"],
  endpoints: () => ({}),
});
