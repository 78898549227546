import React from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import logo from "../assets/sigmoid-logo.png";

const Header: React.FC = () => {

  return (
    <>
      <header className="header sticky w-full z-50 top-0 bg-[#557153] shadow-md flex items-center justify-between py-2">
        <div className="container px-10 mx-auto flex justify-between">
          <h1 className="w-auto mx-auto">
            <Link to="/" className="flex items-center font-bold text-lg">
              <img src={logo} alt="..." className="w-full h-12 my-1" />
            </Link>

          </h1>

        </div>
      </header>
    </>
  );
}

export default Header;
