import { configureStore } from "@reduxjs/toolkit";
import { sigmoidApi, urlApi } from "./api/index";

const MainStore = configureStore({
  reducer: {
    [sigmoidApi.reducerPath]: sigmoidApi.reducer,
    [urlApi.reducerPath]: urlApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(sigmoidApi.middleware)
      .concat(urlApi.middleware)
});

export default MainStore;
