import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Spinner from "../assets/Spinner";

const ErrorPage = lazy(() => import('../pages/ErrorPage'));
const HomePage = lazy(() => import('../pages/HomePage'));
const BankHolidaysPage = lazy(() => import('../pages/BankHolidaysPage'));
const MbahDukunPage = lazy(() => import('../pages/MbahDukunPage'));
const RodaSemestaPage = lazy(() => import('../pages/RodaSemestaPage'));
const UrlShorter = lazy(() => import('../pages/UrlShorterPage'));
const CatatPage = lazy(() => import('../pages/CatatPage'));
const DetailNotePage = lazy(() => import('../components/notes/DetailNotePage'));
const CreateNotePage = lazy(() => import('../components/notes/CreateNotePage'));

const index = () => {
        return (
                <Suspense fallback={<div><Spinner /></div>}>
                        <Routes>
                                <Route path="*" element={<ErrorPage />} />
                                <Route index element={<HomePage />} />
                                <Route path="/holiday" element={<BankHolidaysPage />} />
                                <Route path="/simbah" element={<MbahDukunPage />} />
                                <Route path="/roda-semesta" element={<RodaSemestaPage />} />
                                <Route path="/urlshorter" element={<UrlShorter />} />
                                <Route path="/catat" element={<CatatPage />} />
                                <Route path="/catat/:id" element={<DetailNotePage />} />
                                <Route path="/catat/create" element={<CreateNotePage />} />
                        </Routes>
                </Suspense>
        )
}

export default index
